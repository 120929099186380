import { useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useMemo, useState } from "react";
import useFetchData from "../../../hooks/useFetchData";

export const useAccountingAdjustments = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [selectedDataAdjustment, setSelectedDataAdjustment] = useState(null);

  const { data, isFetching, error, refetch } = useQuery(
    ["data-overrides-acct"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-overrides-acct`,
          { headers }
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const [columnsLookup] = useFetchData("list-data-columns", [], true);
  const columnsLookupForTable = useMemo(() => {
    let converted = {};
    if (columnsLookup?.length > 0) {
      columnsLookup.forEach((item) => {
        converted[item.col_ndx] = item.col_name;
      });
    }
    return converted;
  }, [columnsLookup]);

  const [accountingPackagesLookup] = useFetchData(
    "list-accounting-packages",
    [],
    true
  );
  const accountingPackagesForTable = useMemo(() => {
    let converted = {};
    if (accountingPackagesLookup?.length > 0) {
      accountingPackagesLookup.forEach((item) => {
        converted[item.ap_ndx] = item.ap_name;
      });
    }
    return converted;
  }, [accountingPackagesLookup]);

  const COLUMNS = [
    {
      title: "Collection Date",
      field: "collect_date",
      type: "date",
    },
    {
      title: "Accounting Package",
      field: "ap_ndx",
      lookup: accountingPackagesForTable,
    },
    {
      title: "Column",
      field: "col_ndx",
      lookup: columnsLookupForTable,
    },
    {
      title: "Override Value",
      field: "override_value",
    },
    {
      title: "Notes",
      field: "notes",
    },
  ];

  return {
    selectedDataAdjustment,
    setSelectedDataAdjustment,
    data,
    isFetching,
    error,
    refetch,
    COLUMNS,
  };
};
