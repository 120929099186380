import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";

import { useBasicAccountingAdjustmentsInfo } from "./useBasicAccountingAdjustmentsInfo";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useSubmitData } from "../../../hooks/useSubmitData";
import Loader from "../../../components/Loader";
import { getButtonText, getButtonIcon } from "./utils";

function BasicAccountingAdjustmentsInfo({
  selectedDataAdjustment,
  setSelectedDataAdjustment,
  refetch,
}) {
  const {
    isLoading,
    error,
    handleUpdateState,
    state,
    lookups,
    isDirty,
    handleResetState,
  } = useBasicAccountingAdjustmentsInfo(selectedDataAdjustment);

  const { handleSubmit, isFormSubmitting } = useSubmitData(
    selectedDataAdjustment,
    setSelectedDataAdjustment,
    state,
    "data-overrides-acct",
    "ndx",
    refetch
  );

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      {isLoading ? (
        <Paper style={{ width: "100%", margin: "8px 0" }}>
          <Loader />
        </Paper>
      ) : (
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box style={{ width: "100%" }}>
              <Typography variant="h3">General Information</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={6}
                xl={4}
                style={{
                  position: "relative",
                }}
              >
                <FormControl
                  component="fieldset"
                  style={{ marginRight: "10px", width: "100%" }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      error={!state?.collect_date}
                      required
                      value={state?.collect_date}
                      onChange={(date) =>
                        handleUpdateState("collect_date", date)
                      }
                      id="created-on"
                      variant="inline"
                      format="LLLL do, yyyy"
                      autoOk
                      inputVariant="outlined"
                      label="Collection Date"
                    />
                  </MuiPickersUtilsProvider>
                  {!state?.collect_date && (
                    <FormHelperText error>*Required field</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={4}
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={!state.ap_ndx}
                  required
                >
                  {!lookups?.accountingPackages.length > 0 ? (
                    <Loader />
                  ) : (
                    <>
                      <InputLabel id="ap_ndx">Accounting Package</InputLabel>
                      <Select
                        labelId="ap_ndx"
                        id="ap_ndx"
                        label="Accounting Package"
                        name="accounting-package"
                        value={state.ap_ndx ?? ""}
                        onChange={(e) => {
                          handleUpdateState("ap_ndx", e.target.value);
                        }}
                      >
                        {lookups?.accountingPackages.map((option) => (
                          <MenuItem key={option.ap_ndx} value={option.ap_ndx}>
                            {option.ap_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                  {!state.ap_ndx && (
                    <FormHelperText error>*Required field</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={4}
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  error={!state.col_ndx}
                  required
                >
                  {!lookups?.columns.length > 0 ? (
                    <Loader />
                  ) : (
                    <>
                      <InputLabel id="col_ndx">Column</InputLabel>
                      <Select
                        labelId="col_ndx"
                        id="col_ndx"
                        label="Column"
                        name="column"
                        value={state.col_ndx ?? ""}
                        onChange={(e) => {
                          handleUpdateState("col_ndx", e.target.value);
                        }}
                      >
                        {lookups?.columns.map((option) => (
                          <MenuItem key={option.col_ndx} value={option.col_ndx}>
                            {option.col_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                  {!state.col_ndx && (
                    <FormHelperText error>*Required field</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                xl={4}
                style={{
                  position: "relative",
                }}
              >
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <TextField
                    required
                    error={
                      state.override_value === null ||
                      state.override_value === undefined ||
                      state.override_value === ""
                    }
                    type="number"
                    variant="outlined"
                    label="Adjustment Value"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        handleUpdateState("override_value", null);
                      } else {
                        handleUpdateState("override_value", +e.target.value);
                      }
                    }}
                    value={state?.override_value ?? ""}
                    InputProps={{
                      inputProps: {
                        onKeyPress: (e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        },
                      },
                    }}
                  />
                  {(state.override_value === null ||
                    state.override_value === undefined ||
                    state.override_value === "") && (
                    <FormHelperText error>*Required field</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                xl={8}
                style={{
                  position: "relative",
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  required
                >
                  <TextField
                    multiline
                    rows={3}
                    variant="outlined"
                    label="Notes"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      handleUpdateState("notes", e.target.value);
                    }}
                    value={state?.notes ?? ""}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      <AccordionActions>
        <Button size="small" onClick={handleResetState}>
          Reset
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
          disabled={
            !isDirty ||
            !state.collect_date ||
            !state.ap_ndx ||
            !state.col_ndx ||
            state.override_value === null ||
            state.override_value === undefined ||
            state.override_value === "" ||
            isFormSubmitting
          }
          startIcon={getButtonIcon(isFormSubmitting)}
          style={{ width: "100px" }}
        >
          {getButtonText(isFormSubmitting, selectedDataAdjustment === 0)}
        </Button>
      </AccordionActions>
    </>
  );
}

export default BasicAccountingAdjustmentsInfo;
