import React, { useCallback, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { DatePicker, MultiSelect, Select } from "@lrewater/lre-react";
import {
  dateFormatter,
  extractDate,
  getLookupForDataSortedByField,
  standardizeColor,
} from "../../../utils";
import styled from "styled-components/macro";
import {
  Accordion,
  AccordionDetails,
  Button as MuiButton,
  Box,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Typography as MuiTypography,
  Tooltip,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  FormControlLabel,
} from "@material-ui/core";
import {
  VerticalAlignBottom,
  ArrowDownward,
  TrendingFlat,
  ArrowUpward,
  VerticalAlignTop,
  ExpandMore,
  HelpOutline,
} from "@material-ui/icons";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Panel from "../../../components/panels/Panel";
import SaveGraphButton from "../../../components/graphs/SaveGraphButton";
import { spacing } from "@material-ui/system";
import Table from "../../../components/Table";
import { Helmet } from "react-helmet-async";
import { add } from "date-fns";
import TimeseriesLineChart from "../../../components/graphs/TimeseriesLineChart";
import { NavLink } from "react-router-dom";
import { useApp } from "../../../AppProvider";
import useFetchData from "../../../hooks/useFetchData";

const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
`;

const TimeseriesContainer = styled.div`
  height: 600px;
  width: 100%;
`;

const LastReportContainer = styled.div`
  max-height: 600px;
  width: 100%;
`;

const SubmitGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 10px;
  width: 100%;
`;

const createDataset = (location, yAxisID) => ({
  data: location.map((item) => ({
    x: item.collect_timestamp,
    y: item.result_value,
  })),
  yAxisID: yAxisID,
  units: location[0].unit_display_label,
  pointStyle: "circle",
  fill: false,
  borderWidth: yAxisID === "yR" ? 3 : 2,
  spanGaps: true,
  pointRadius: 2,
  pointHoverRadius: 4,
  label: `${location[0].location_display_label}${
    yAxisID === "yR" ? " (R)" : ""
  }`,
  borderColor: location[0].legend_color,
  backgroundColor: standardizeColor(location[0].legend_color),
  tension: 0.5,
  ...(yAxisID === "yR" ? { borderDash: [10, 5] } : {}),
});

const QuickSetButton = ({ onClick, children }) => (
  <Tooltip title="Quick set dates">
    <Button
      ml={3}
      onClick={onClick}
      color="primary"
      variant="contained"
      size="small"
    >
      {children}
    </Button>
  </Tooltip>
);

const flowDirectionMapping = {
  "-2": {
    Icon: VerticalAlignBottom,
    text: "Decreasing Rapidly",
    tooltipText: "Flow is decreasing rapidly (>10% in 1 hour)",
  },
  "-1": {
    Icon: ArrowDownward,
    text: "Decreasing",
    tooltipText: "Flow is decreasing",
  },
  0: { Icon: TrendingFlat, text: "Steady", tooltipText: "Flow is steady" },
  1: {
    Icon: ArrowUpward,
    text: "Increasing",
    tooltipText: "Flow is increasing",
  },
  2: {
    Icon: VerticalAlignTop,
    text: "Increasing Rapidly",
    tooltipText: "Flow is increasing rapidly (>10% in 1 hour)",
  },
  fallback: {
    Icon: HelpOutline,
    text: "N/A",
    tooltipText: "Information not available",
  },
};

const DwrGraphs = ({
  defaultWatersheds,
  defaultLocations,
  pageTitle,
  endpoints,
  watershedsEndpoint,
  locationsEndpoint,
}) => {
  const saveRef = useRef(null);
  const { currentUser } = useApp();

  const [filterValues, setFilterValues] = useState({
    endpoint: endpoints[0].value,
    watersheds: defaultWatersheds,
    locations: defaultLocations,
    startDate: extractDate(add(new Date(), { days: -3 })),
    endDate: extractDate(new Date()),
    dateFormat: "MM-DD-YYYY, h:mm A",
  });

  const [WateryearDates] = useFetchData("wateryear-start-end-dates", [], false);

  const {
    last_wateryear_start,
    last_wateryear_end,
    current_wateryear_start,
    current_wateryear_end,
  } = WateryearDates[0] || {};

  const setQuickFilter = useCallback(({ start, end, days }) => {
    const startDate =
      days !== undefined ? add(new Date(), { days: -days }) : new Date(start);
    const endDate = end ? new Date(end) : new Date();

    setFilterValues((prev) => ({
      ...prev,
      startDate: extractDate(startDate),
      endDate: extractDate(endDate),
    }));
  }, []);

  const { data: Watersheds } = useQuery(
    [watershedsEndpoint],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${watershedsEndpoint}`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data: Locations } = useQuery(
    [locationsEndpoint],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${locationsEndpoint}`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const handleFilter = useCallback(
    ({ target: { name, value } }) => {
      const getAssociatedLocationIndices = (selectedWatersheds) =>
        Watersheds.filter((watershed) =>
          selectedWatersheds.includes(watershed.watershed_ndx)
        ).reduce(
          (acc, { assoc_location_ndx }) => [...acc, ...assoc_location_ndx],
          []
        );

      const filterLocationsByWatershed = (
        selectedLocations,
        associatedLocationNdxs
      ) =>
        selectedLocations.filter((locationNdx) =>
          associatedLocationNdxs.includes(locationNdx)
        );

      if (name === "watersheds") {
        const associatedLocationNdxs = getAssociatedLocationIndices(value);
        const filteredLocations = filterLocationsByWatershed(
          filterValues.locations || [],
          associatedLocationNdxs
        );

        setFilterValues((prev) => ({
          ...prev,
          [name]: value,
          locations: filteredLocations,
        }));
      } else if (name === "endpoint") {
        // Reset date filters when endpoint changes
        setFilterValues((prev) => ({
          ...prev,
          [name]: value,
          startDate: extractDate(
            add(new Date(), {
              days: value === "display-dwrstreamflow15-min" ? -3 : -14,
            })
          ),
          endDate: extractDate(new Date()),
        }));
      } else {
        setFilterValues((prev) => ({ ...prev, [name]: value }));
      }
    },
    [Watersheds, filterValues.locations]
  );

  const {
    data: dataLastReport,
    isFetching: isLoadingLastReport,
    refetch: refetchLastReport,
  } = useQuery(
    ["display-dwrstreamflow-last-report"],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/display-dwrstreamflow-last-report/${filterValues.locations}`
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      enabled: false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  const { data, error, isFetching, refetch } = useQuery(
    [pageTitle],
    async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/${filterValues.endpoint}/${
            filterValues.locations
          }/${filterValues.startDate}/${extractDate(
            add(new Date(filterValues.endDate), { days: 2 })
          )}`
        );

        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      onSuccess: () => {
        setFilterValues((prev) => {
          const { endpoint } = prev;
          if (endpoint === "display-dwrstreamflow-daily") {
            return {
              ...prev,
              dateFormat: "MM-DD-YYYY",
            };
          } else if (endpoint === "display-dwrstreamflow15-min") {
            return {
              ...prev,
              dateFormat: "MM-DD-YYYY, h:mm A",
            };
          }
        });
        refetchLastReport();
      },
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  const getFilteredData = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.flat().filter((record) => record.result_value !== null);
  };

  const tableColumns = useMemo(
    () => [
      {
        title: "Loc ID",
        field: "location_id",
        lookup: getLookupForDataSortedByField(
          getFilteredData(data),
          "location_id"
        ),
      },
      {
        title: "Location Name",
        field: "location_name",
        lookup: getLookupForDataSortedByField(
          getFilteredData(data),
          "location_name"
        ),
        cellStyle: {
          width: "500px",
          minWidth: "500px",
          whiteSpace: "nowrap",
        },
      },
      {
        title: "Timestamp",
        field: "collect_timestamp",
        render: (rowData) => {
          return dateFormatter(
            rowData.collect_timestamp,
            filterValues.dateFormat
          );
        },
        filtering: false,
      },
      { title: "Value", field: "result_value", filtering: false },
      { title: "Units", field: "unit_display_label", filtering: false },
      {
        title: "Watershed",
        field: "watershed_desc",
        lookup: getLookupForDataSortedByField(
          getFilteredData(data),
          "watershed_desc"
        ),
      },
      { title: "Source", field: "datasrc_name", filtering: false },
      { title: "WDID", field: "wdid", filtering: false },
    ],
    [data, filterValues.dateFormat]
  );

  const lastReportColumns = useMemo(
    () => [
      { title: "Location", field: "location_id" },
      {
        title: "Last Reported Value",
        field: "result_value",
        render: (rowData) => `${rowData.result_value} ${rowData.unit_desc}`,
      },
      {
        title: "Last Reported Timestamp",
        field: "most_recent_timestamp",
        render: (rowData) => {
          return dateFormatter(
            rowData.most_recent_timestamp,
            "MM/DD/YYYY, h:mm A"
          );
        },
      },
      { title: "Source", field: "data_source" },
      {
        title: "Flow Trend",
        field: "incr_decr",
        render: (rowData) => {
          const mapping =
            flowDirectionMapping[rowData.incr_decr] ||
            flowDirectionMapping["fallback"];

          const { Icon, text, tooltipText } = mapping;

          return (
            <Tooltip title={tooltipText} aria-label={tooltipText}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Icon />
                <span style={{ textAlign: "center" }}>{text}</span>
              </div>
            </Tooltip>
          );
        },
      },
    ],
    [data] //eslint-disable-line
  );

  const graphData = useMemo(() => {
    if (!data) return { datasets: [] };

    const leftAxisDatasets = data.map((location) =>
      createDataset(location, "yL")
    );

    return { datasets: leftAxisDatasets };
  }, [data]);

  const firstItem = data?.[0]?.[0] ?? {};
  const { parameter_display_label = "N/A", unit_display_label = "N/A" } =
    firstItem;

  const getFilteredLocations = () => {
    if (
      !Locations ||
      !filterValues.watersheds ||
      filterValues.watersheds.length === 0
    ) {
      return [];
    }

    const selectedWatersheds = Watersheds.filter((watershed) =>
      filterValues.watersheds.includes(watershed.watershed_ndx)
    );

    const associatedLocationNdxs = selectedWatersheds.flatMap(
      (watershed) => watershed.assoc_location_ndx
    );

    return Locations.filter((location) =>
      associatedLocationNdxs.includes(location.location_ndx)
    );
  };

  // Determine available QuickSetButtons based on the selected endpoint
  const availableQuickSets = useMemo(() => {
    if (filterValues.endpoint === "display-dwrstreamflow15-min") {
      return [
        { label: "Last 45 Days", days: 45 },
        { label: "Last 14 Days", days: 14 },
        { label: "Last 3 Days", days: 3 },
      ];
    } else {
      // Daily data options
      return [
        {
          label: "Last and Current Water Years",
          start: last_wateryear_start,
          end: current_wateryear_end,
        },
        {
          label: "Last Water Year",
          start: last_wateryear_start,
          end: last_wateryear_end,
        },
        {
          label: "Current Water Year",
          start: current_wateryear_start,
          end: current_wateryear_end,
        },
        { label: "Last 45 Days", days: 45 },
        { label: "Last 14 Days", days: 14 },
        { label: "Last 3 Days", days: 3 },
      ];
    }
  }, [
    filterValues.endpoint,
    last_wateryear_start,
    last_wateryear_end,
    current_wateryear_start,
    current_wateryear_end,
  ]);

  return (
    <>
      <Helmet title={pageTitle} />
      <Typography variant="h3" gutterBottom display="inline">
        {pageTitle}
      </Typography>
      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Typography>{pageTitle}</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <>
        {data && Watersheds && Locations && (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="time-series"
                  id="time-series"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Typography variant="h4" ml={2}>
                      {`${pageTitle} / ${parameter_display_label}, ${unit_display_label}`}{" "}
                    </Typography>
                    <FormControlLabel
                      aria-label="save graph button"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <SaveGraphButton
                          ref={saveRef}
                          title={`${pageTitle} / ${parameter_display_label}, ${unit_display_label}`}
                          disabled={isFetching || !data}
                        />
                      }
                      label=""
                    />
                  </Box>
                </AccordionSummary>
                <Panel>
                  <AccordionDetails>
                    <Grid container spacing={3} style={{ height: "100%" }}>
                      <Grid xs={12} xl={9} item>
                        <TimeseriesContainer>
                          <Box
                            pb={1}
                            display="flex"
                            alignItems="center"
                            style={{ width: "100%" }}
                          >
                            <span
                              style={{
                                marginLeft: "auto",
                              }}
                            />
                          </Box>
                          <TableWrapper>
                            <TimeseriesLineChart
                              data={graphData}
                              error={error}
                              isLoading={isFetching}
                              reverseLegend={false}
                              filterValues={filterValues}
                              yLLabel={`${parameter_display_label} (${unit_display_label})`}
                              xLabelUnit="day"
                              ref={saveRef}
                              title={`${pageTitle} / ${parameter_display_label}, ${unit_display_label}`}
                              tooltipFormat={filterValues.dateFormat}
                            />
                          </TableWrapper>
                        </TimeseriesContainer>
                      </Grid>
                      <Grid xs={12} xl={3} item>
                        <LastReportContainer>
                          <Typography variant="h4" ml={2}>
                            Last Reported Values
                          </Typography>
                          <Table
                            isLoading={isLoadingLastReport}
                            columns={lastReportColumns}
                            data={dataLastReport}
                            height="600px"
                            options={{
                              search: false,
                              toolbar: false,
                              paging: false,
                              showTitle: true,
                            }}
                          />
                        </LastReportContainer>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Panel>
              </Accordion>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={6}>
          {Watersheds && Locations && WateryearDates && (
            <>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="options"
                    id="options-header"
                  >
                    <Typography variant="h4" ml={2}>
                      Options
                    </Typography>
                  </AccordionSummary>
                  <Panel>
                    <AccordionDetails>
                      <Grid container pb={6} mt={2}>
                        <Grid item xs={12}>
                          <Select
                            data={endpoints}
                            valueField="value"
                            displayField="label"
                            onChange={(e) => {
                              const selectedEndpoint = e.target.value;
                              handleFilter({
                                target: {
                                  name: "endpoint",
                                  value: selectedEndpoint,
                                },
                              });
                            }}
                            value={filterValues.endpoint}
                            name="endpoint"
                            label="Data Frequency"
                            variant="outlined"
                            fillColor="primary"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                            style={{ width: "100%", marginTop: "16px" }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MultiSelect
                            data={Watersheds}
                            valueField="watershed_ndx"
                            displayField="watershed_desc"
                            onChange={(e) => {
                              if (e.target.value.includes("all/none")) {
                                return null;
                              } else {
                                handleFilter({
                                  target: {
                                    name: "watersheds",
                                    value: e.target.value,
                                  },
                                });
                              }
                            }}
                            value={filterValues.watersheds}
                            name="watersheds"
                            label="Watersheds"
                            variant="outlined"
                            fillColor="primary"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                            style={{ width: "calc(50% - 166px)" }}
                          />
                          <MultiSelect
                            data={getFilteredLocations()}
                            valueField="location_ndx"
                            displayField="location_display_label"
                            onChange={(e) => {
                              if (e.target.value.includes("all/none")) {
                                return null;
                              } else {
                                handleFilter({
                                  target: {
                                    name: "locations",
                                    value: e.target.value,
                                  },
                                });
                              }
                            }}
                            value={filterValues.locations}
                            name="locations"
                            label="Locations"
                            variant="outlined"
                            fillColor="primary"
                            outlineColor="primary"
                            labelColor="primary"
                            margin="normal"
                            style={{ width: "calc(50% - 166px)" }}
                          />
                          <DatePicker
                            name="startDate"
                            label="Start Date"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            value={filterValues.startDate}
                            onChange={handleFilter}
                            width={150}
                          />
                          <DatePicker
                            name="endDate"
                            label="End Date"
                            variant="outlined"
                            outlineColor="primary"
                            labelColor="primary"
                            value={filterValues.endDate}
                            onChange={handleFilter}
                            width={150}
                          />
                        </Grid>

                        <SubmitGrid item container>
                          <Grid>
                            <Button
                              onClick={async () => {
                                await refetch();
                              }}
                              type="submit"
                              color="secondary"
                              variant="contained"
                              size="large"
                              disabled={
                                !filterValues.startDate ||
                                !filterValues.endDate ||
                                isFetching ||
                                !filterValues?.locations?.length
                              }
                            >
                              Submit
                            </Button>
                          </Grid>
                          <Grid item style={{ marginLeft: "auto" }}>
                            {availableQuickSets.map((qs) =>
                              !!qs.days ? (
                                <QuickSetButton
                                  key={qs.label}
                                  onClick={() =>
                                    setQuickFilter({ days: qs.days })
                                  }
                                >
                                  {qs.label}
                                </QuickSetButton>
                              ) : (
                                <QuickSetButton
                                  key={qs.label}
                                  onClick={() =>
                                    setQuickFilter({
                                      start: qs.start,
                                      end: qs.end,
                                    })
                                  }
                                >
                                  {qs.label}
                                </QuickSetButton>
                              )
                            )}
                          </Grid>
                        </SubmitGrid>
                      </Grid>
                    </AccordionDetails>
                  </Panel>
                </Accordion>
              </Grid>
            </>
          )}
        </Grid>

        {data && !isFetching && (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="table-content"
                  id="table-header"
                >
                  <Typography variant="h4" ml={2}>
                    Table
                  </Typography>
                </AccordionSummary>
                <Panel>
                  <AccordionDetails>
                    <TableWrapper>
                      <Table
                        className="myMaterialTableId"
                        // isLoading={isLoading}
                        label={`${pageTitle} / ${parameter_display_label}, ${unit_display_label}`}
                        columns={tableColumns}
                        data={getFilteredData(data)}
                        height="590px"
                        options={{ filtering: true }}
                      />
                    </TableWrapper>
                  </AccordionDetails>
                </Panel>
              </Accordion>
            </Grid>
          </Grid>
        )}
      </>
    </>
  );
};

export default DwrGraphs;
