import React from "react";

const mapStyles = {
  height: "100%",
  position: "relative",
  width: "100%",
};

const MapWadt = React.forwardRef(function Map({ children }, ref) {
  return (
    <section style={{ position: "relative", height: "100%" }}>
      <div ref={ref} style={mapStyles} id="public-map" tabIndex="-1" />
      {children}
    </section>
  );
});

export default MapWadt;
